<template>
  <div>
    <!-- <headers></headers> -->
    <div class="agreement" v-html="html"></div>
    <footer></footer>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import footers from '@/components/footer'
import headers from '@/components/head'
import { getAgreement } from '@/api/agreement.js'
export default {
  components: {
    footers,
    headers
  },
  data() {
    return {
      html: '',
      type: this.$route.query.type || '4',
      agency_id: Cookies.get('agency_id')
    }
  },
  created() {
    // 请求数据
    this.getAgreement()
  },
  methods: {
    getAgreement() {
      var info = {
        type: this.type
      }
      const origin = window.location.origin
      if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
        info.zkb_pro = 1
      }
      getAgreement(info).then((res) => {
        console.log(res)
        let errNo = res.errNo
        if (errNo == 0) {
          let result = res.result || {}
          this.html = result.agreement || ''

        }
      })
    },
    back(e) {
      if (e.target.innerText === '阅读完毕，关闭窗口') {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style>
body {
  /* background: url(../img/reg_img/tkbg.gif); */
  width: 100%;
  height: 100%;
  color: #444;
}
/*footer样式*/
p {
  margin: 0;
  padding: 0;
}
.zhul_footer_warpper {
  clear: both;
  width: 940px;
  margin: 0 auto;
}
.zhul_footer {
  float: left;
  width: 940px;
  padding: 20px 0;
  text-align: center;
  color: #444;
  display: inline;
  font-size: 13px;
  line-height: 25px;
}
.zhul_botmenu_font {
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.zhul_botmenu_pic {
  width: 900px;
  margin: 0 auto;
  padding-top: 20px;
}
.zhul_botmenu_link {
  padding: 0 20px 0 20px;
  border-right: solid 1px #ccc;
  color: #444;
  text-decoration: none;
  font-size: 14px;
  font-family: "微软雅黑", Verdana, Arial, Helvetica, sans-serif;
}
.zhul_botmenu_unbor {
  padding: 0 20px 0 20px;
  border-right: none;
  color: #444;
  text-decoration: none;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.zhul_botmenu_c {
  clear: both;
}
.zhul_tk_warp {
  width: 960px;
  margin: 0 auto;
}
.zhul_tk_warpbg {
  float: left;
  width: 940px;
  padding: 10px 10px 20px 10px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
.zhul_tk_main {
  width: 780px;
  margin: 0 auto;
}
.zhul_tk_title {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
}
.zhul_tk_title span {
  color: #2ba2d6;
}
.zhul_tk_content {
  float: left;
  width: 778px;
  border: 1px solid #d2d2d2;
  border-top: 3px solid #d2d2d2;
  padding: 15px 0;
}
.zhul_tk_contop {
  float: left;
  /* background: url(../assets/zhul_tk_topbg.gif) repeat-x; */
  width: 748px;
  height: 20px;
  margin-left: 15px;
  display: inline;
}
.zhul_tk_toptitle {
  float: left;
  background: #fff;
  font-size: 16px;
  padding: 0 10px;
  color: #2ba2d6;
  margin-left: 25px;
  display: inline;
}
.zhul_tk_tishi {
  float: left;
  width: 600px;
  font-size: 14px;
  padding: 10px 0 0 50px;
}
.zhul_tk_info {
  float: left;
  width: 680px;
  padding: 30px 0 30px 50px;
}
.zhul_tk_textarea {
  width: 658px;
  border: 1px solid #8d97a3;
  height: 380px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  color: #444;
}
.zhul_tk_bot {
  float: left;
  width: 100%;
  border-top: 1px solid #eee;
  padding: 20px 0 5px 0;
  text-align: center;
}

.close {
  padding: 3px 48px;
  font-size: 16px;
  background-color: #2ba2d6;
  color: #fff;
  border: 0;
  border-radius: 5px;
}
.agreement {
  width: 1200px !important;
  margin: 70px auto 0;
  padding: 30px;
  background-color: #fff;
  text-align: left !important;
}
.zhul_footer {
  width: 100% !important;
}
.zhul_bot_footer {
  width: 100% !important;
}
</style>
